
import {
    defineComponent,
    PropType,
    computed,
    ref,
    Ref
} from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router/index';

type OpenType = 'routerPush' | 'windowOpen' | 'callBack';
interface MenuItem {
    name: string;
    label: string;
    icon: string;
    path: string;
    type?: OpenType;
    children?: Array<{
        [key in string]: string;
    }>;
}

export default defineComponent({
    props: {
        menus: {
            type: Array as PropType< Array<MenuItem> >,
            required: true
        }
    },
    setup(props) {
        const showSubMenu: Ref<Array<boolean>> = ref([]);
        props.menus.forEach(() => {
            showSubMenu.value.push(false);
        });
        const activeName = computed(() => {
            const name = useRoute().matched[1] ? useRoute().matched[1].name : '';
            // 匹配二级目录自动展开列表
            props.menus.forEach((item, index) => {
                if (item.children) {
                    item.children.forEach((children) => {
                        if (children.name === name) {
                            showSubMenu.value[index] = true;
                        }
                    });
                }
            });
            return name;
        });

        const changeRouter = (path: string | Function, type: OpenType = 'routerPush') => {
            if (type === 'routerPush') {
                // 重复点击菜单，重新加载
                if (router.currentRoute.value.fullPath === path) window.location.reload();
                else router.push(path);
            } else if (type === 'windowOpen') {
                window.open(path as string);
            } else if (type === 'callBack') {
                (path as Function)();
            }
        };
        return {
            changeRouter,
            activeName,
            showSubMenu
        };
    }
});
